// Fetching Products

export default async function fetchProducts() {
  try {
    const res = await fetch('https://beadsandpearls.in/api/products', {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    return await res.json();
  } catch (error) {
    throw new Error(error);
  }
}