import React from 'react'

export const reducer = (state, action) => {

  if (action.type === "REMOVE_ITEM") {
    let updatedWishlist = state.wishlist.filter(
      (curItem) => curItem.MainProduct.productId !== action.payload
    );
    return {
      ...state,
      wishlist: updatedWishlist,
    };
  }


  if (action.type === "ADD_TO_WISHLISH") {
    let { MainProduct, amount, size } = action.payload;

    let existingProduct = state.wishlist.find(
      (curItem) => curItem.MainProduct.productId === MainProduct.productId);

    if (existingProduct) {
      let updatedProduct = state.wishlist.map((curElem) => {

        if (curElem.MainProduct.productId === MainProduct.productId) {

          let newAmount = curElem.amount + amount;

          return {
            ...curElem,
            amount: newAmount,
            size
          }

        } else {
          return curElem;
        }
      })
      return {
        ...state,
        wishlist: updatedProduct
      }
    } else {
      return {
        ...state,
        wishlist: [...state.wishlist, { MainProduct, amount, size }]
      }
    }
  }

  return state;
}
