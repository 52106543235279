import React, { useEffect } from 'react'
import { OnlineDesigner } from './OnlineDesigner'

export const Faq = () => {

  useEffect(() => {
    document.title = 'FAQs';
  }, [])


  return (
    <div className='Login'>
      <OnlineDesigner title="FAQs" />
      <div className="Faq">
        <details>
          <summary>Is there any guarantee/warranty for products?</summary>
          <div className="ShippingPolicyMain">
            <p>No. There is no explicit guarantee / warranty. But we promise to deliver premium quality product to you.</p>
          </div>
        </details>
        <details>
          <summary>Can I Replace or Exchange the Product if I Don't Like?</summary>
          <div className="ShippingPolicyMain">
            <p>Replacement or exchange shall be given only if there are any damages and within 2 days of delivery, if it's more than 2 days then there shall be no replacement/exchange (Unboxing video is must). For replacement, the item needs to be couriered back to the address we provide and we shall replace it and courier once we get back your parcel.</p>
          </div>
        </details>
        <details>
          <summary>Is Package opening Video a must?</summary>
          <div className="ShippingPolicyMain">
            <p>Yes, Incase of any missing items from your package please kindly provide us a complete package opening video without any cuts to
facilitate easier help for you.</p>
          </div>
        </details>
        <details>
          <summary>What if the package returns back?</summary>
          <div className="ShippingPolicyMain">
            <p> If the package returns back to us stating that the customer was unavailable while they came for delivery or they couldn't reach out to the customer's phone number then the package will return back to us and the customer will have to pay reshipping charges and then once the package returns back to us we will Reship it (no refund will be provided).</p>
          </div>
        </details>
        <details>
          <summary>Can we Cancel the Order?</summary>
          <div className="ShippingPolicyMain">
            <p>No. Once the Order has been placed there is
no Cancellation.</p>
          </div>
        </details>
        <details>
          <summary>Will the Product be Exactly as shown in the Picture?</summary>
          <div className="ShippingPolicyMain">
            <p>As these are handcrafted by our local artisans so there shall be minute differences from piece to piece but not any major differences, no
exchange shall be given based on a small minute differences!</p>
          </div>
        </details>
        <details>
          <summary>When and How do I Track my Parcel?</summary>
          <div className="ShippingPolicyMain">
            <p>Once the item is shipped, the tracking shall be updated to your SMS/MAIL, Please click on the Tracking number and Track your Parcel.</p>
          </div>
        </details>
        <details>
          <summary>How do I know if my order has been
confirmed?</summary>
          <div className="ShippingPolicyMain">
            <p>You will receive an order confirmation email.</p>
          </div>
        </details>
        <details>
          <summary>My item has arrived damaged or incorrect. What do I do?</summary>
          <div className="ShippingPolicyMain">
            <p>If we have made a mistake from our end by sending you an incorrect item or damaged item, please send us an email at contact@beadsandpearls.in with a photograph of the garment. We will return it to you with the correct item.</p>
          </div>
        </details>
        <details>
          <summary>How do I redeem a promotional code?</summary>
          <div className="ShippingPolicyMain">
            <p>Simply enter your valid promo code at checkout.</p>
          </div>
        </details>
      </div>
    </div>
  )
}
