import { createContext, useContext, useReducer, useEffect } from "react";
import { reducer } from "../reducer/WishlistReducer";

// creating Context using context API
const WishListContext = createContext();


const getLocalWishListData = () => {
  let localWishListData = localStorage.getItem('WishListItems');
  if (!localWishListData) {
    return [];
  } else {
    return JSON.parse(localWishListData);
  }
}

// Defining initial State for the useReducer
const initialState = {
  wishlist: getLocalWishListData(),
  buyNowProduct: "",
  total_item: "",
  orderInfo: null // Add orderInfo to the initial state
}
const WishListProvider = ({ children }) => {

  // Defining useReducer, dispatch, state  
  const [state, dispatch] = useReducer(reducer, initialState);

  // Add products to wishlist
  const addToWishlist = (MainProduct, amount, size) => {
    dispatch({ type: "ADD_TO_WISHLISH", payload: { MainProduct, amount, size } });
  };

  const BuyNow = (MainProduct, amount, size) => {
    dispatch({ type: "BUY_NOW", payload: { MainProduct, amount, size } });
  };

  // Add products to wishlist
  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  // Decreament Product Quantity
  const setDecreament = (id) => {
    dispatch({ type: "SET_DECREAMENT", payload: id })
  }

  // Increament Product Quantity
  const setIncreament = (id) => {
    dispatch({ type: "SET_INCREAMENT", payload: id })
  }
  // Send User Data to Shipping
  const sendUserData = (usInfo) => {
    dispatch({ type: "USER_DATA", payload: usInfo })
  }
  const sendOrderData = (orderInfo) => {
    localStorage.setItem('orderInfo', JSON.stringify(orderInfo));
    dispatch({ type: "ORDER_INFO", payload: orderInfo })
  }

  useEffect(() => {
    localStorage.setItem('WishListItems', JSON.stringify(state.wishlist));
  }, [state])

  // useEffect(() => {
  //   localStorage.setItem('orderInfo', JSON.stringify(state.orderInfo));
  // }, [state.orderInfo]);

  // Creating the Provider
  return (<WishListContext.Provider value={{ ...state, addToWishlist, BuyNow, removeItem, setIncreament, setDecreament, sendUserData, sendOrderData }}>
    {children}
  </WishListContext.Provider>);
}

// Creating use context API
const useWishListContext = () => {
  return useContext(WishListContext);
}

export { WishListProvider, useWishListContext};